// index.ts
import { destination } from "@turf/destination";
import { polygon } from "@turf/helpers";
function circle(center, radius, options = {}) {
  const steps = options.steps || 64;
  const properties = options.properties ? options.properties : !Array.isArray(center) && center.type === "Feature" && center.properties ? center.properties : {};
  const coordinates = [];
  for (let i = 0; i < steps; i++) {
    coordinates.push(destination(center, radius, i * -360 / steps, options).geometry.coordinates);
  }
  coordinates.push(coordinates[0]);
  return polygon([coordinates], properties);
}
var turf_circle_default = circle;
export { circle, turf_circle_default as default };
