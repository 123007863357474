import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, filter, Subject, switchMap } from 'rxjs';

import {
  Map, GeoJSONSource, LngLatBounds, Popup, NavigationControl, Marker, FullscreenControl, LngLatLike, SourceSpecification
} from 'mapbox-gl';

import { circle } from '@turf/circle';
import { point } from '@turf/helpers';
import { Feature, Position } from 'geojson';

import { Coordinates, MapGetData, MapMode, MapSetData } from '@/models';
import { MAP_CENTER_FRANCE, MAP_STYLE } from '@/constants';
import { ConfigService } from './config.service';

export type MapOptions = {
  fixed: boolean;
  mode: MapMode;
};

type MapLayerConfig = {
  layer: 'biens' | 'circle' | 'outline' | '3d-buildings',
  source?: 'biens' | 'circleData' | 'points1' | 'polysOnMap'
};

type MapItem = 'biens' | 'circle' | 'polygons' | 'buildings';

const MAP_IDS: Record<MapItem, MapLayerConfig> = {
  biens: { layer: 'biens', source: 'biens' },
  circle: { layer: 'circle', source: 'circleData' },
  polygons: { layer: 'outline', source: 'polysOnMap' },
  buildings: { layer: '3d-buildings' }
};

export type MapImage = {
  name: MapPoi;
  url: string;
  size: number;
};

export enum MapPoi {
  Bien = 'bien',
  Agence = 'agence',
  Commerce = 'commerce',
  Sante = 'sante',
  Transport = 'transport',
  Loisir = 'loisir',
  Education = 'education'
};

const MAP_IMAGES: Record<MapMode, MapImage[]> = {
  annonces: [],
  agences: [{
    name: MapPoi.Agence,
    url: '/assets/imgs/era-logo-marker.png',
    size: 1
  }],
  annonce: [{
    name: MapPoi.Bien,
    url: '/assets/imgs/pin-home.png',
    size: 3
  }, {
    name: MapPoi.Commerce,
    url: '/assets/imgs/pin-commerce.png',
    size: 0.3
  }, {
    name: MapPoi.Sante,
    url: '/assets/imgs/pin-sante.png',
    size: 0.3
  }, {
    name: MapPoi.Transport,
    url: '/assets/imgs/pin-transport.png',
    size: 0.3
  }, {
    name: MapPoi.Loisir,
    url: '/assets/imgs/pin-hobbies.png',
    size: 0.3
  }, {
    name: MapPoi.Education,
    url: '/assets/imgs/pin-education.png',
    size: 0.3
  }]
} as const;

const DEFAULT_MAP_OPTIONS: MapOptions = { fixed: false, mode: 'annonces' };
const DEFAULT_CENTER_ZOOM = 7;

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private map: Map;
  private polygon = '';
  private images: MapImage[] = [];
  private popupDisplay?: Popup;
  private tooltipDisplay?: Popup;
  private accessToken?: string;
  private options?: MapOptions;
  private marker?: Marker;
  private stickyCircle = false;
  private mapCenter?: Coordinates<number> & { zoom: number };

  private searchOnMapMove = new BehaviorSubject<boolean>(false);
  private setData = new BehaviorSubject<MapSetData>(undefined);
  private mapReady = new BehaviorSubject<boolean>(false);
  private getData = new Subject<MapGetData>();

  constructor(private configService: ConfigService) {
    this.accessToken = this.configService.config.mapboxKey;
    this.listenEvents();
  }

  get getMapData$() {
    return this.getData.asObservable();
  }

  get setMapData() {
    return this.setData;
  }

  get searchOnMapMove$() {
    return this.searchOnMapMove.asObservable();
  }

  get searchOnMove() {
    return this.searchOnMapMove.value;
  }

  /**
   * Create the map and its associated layers, sources and event handlers
   */
  public createMap(mapContainer: ElementRef, options: MapOptions = DEFAULT_MAP_OPTIONS) {
    // Save the supplied map options
    this.options = options;

    // Create the map object
    this.map = new Map({
      accessToken: this.accessToken,
      container: mapContainer.nativeElement,
      center: MAP_CENTER_FRANCE,
      style: MAP_STYLE,
      antialias: true,
      minZoom: 2,
      zoom: 5
    });

    this.map.on('load', async () => {
      const images = MAP_IMAGES[this.options.mode];
      if (images?.length) {
        this.images = await this.loadImages(images);
      }

      switch (this.options.mode) {
        case 'annonces':
        case 'agences':
          this.addSourcesAndLayers();
          break;
        case 'annonce':
          this.addPoiSourcesAndLayers();
          break;
      }

      this.add3DBuildingsOnZoom();
      this.addEventsHandlers();

      this.map.addControl(new NavigationControl(), 'bottom-right');
      this.map.addControl(new FullscreenControl());

      if (options.fixed) {
        this.map.scrollZoom.disable();
      }

      this.map.resize();

      if (this.mapCenter) {
        this.map.setCenter([this.mapCenter.lng, this.mapCenter.lat]);
        this.map.setZoom(this.mapCenter.zoom);
      }

      // Allow processing external commands
      this.mapReady.next(true);
    });
  }

  /**
   * Destroy created map resources
   */
  public destroyMap(): void {
    if (this.map) {
      this.closePopup();
      this.closeTooltip();
      this.cleanMapPolygon();

      this.removeEventsHandlers();

      switch (this.options.mode) {
        case 'annonces':
        case 'agences':
          this.removeSourcesAndLayers();
          break;
        case 'annonce':
          this.removePoiSourcesAndLayers();
          break;
      }

      this.map.remove();
      this.map = undefined;
    }

    this.images = [];
    this.stickyCircle = false;
    this.mapCenter = undefined;
    this.mapReady.next(false);

    // Empty external data observable for next create
    this.setData.next(undefined);
  }

  /**
   * Resize the map to fit its container.
   */
  public resizeMap(): void {
    if (this.mapReady.value) {
      this.map.resize();
    }
  }

  /**
   * Center map to the given latitude and longitude
   * @param lng Longitude
   * @param lat Latitude
   * @param zoom Zoom factor
   */
  public centerMap(coords?: Coordinates<number>, zoom: number = DEFAULT_CENTER_ZOOM): void {
    this.mapCenter = coords ? { ...coords, zoom } : undefined;

    if (this.mapReady.value) {
      this.map.setCenter(this.mapCenter ? [this.mapCenter.lng, this.mapCenter.lat] : MAP_CENTER_FRANCE);
      this.map.setZoom(zoom);
    }

    this.updateMarkers();
  }

  /**
   * Enable or disable the search annonces on map moves feature
   * @param on true to enable, false to disable.
   */
  public setSearchOnMapMove(on: boolean): void {
    this.searchOnMapMove.next(on);

    if (!on) {
      this.updateMarkers();
    } else {
      this.cleanMapPolygon();
    }
  }

  /**
   * Set new map selection polygon, clear the exiting one if requested.
   * Refresh map markers if needed/requested.
   * @param polygon The polygon definition sring
   */
  public updatePolygon(polygon = '', refreshMapMarkers = false): void {
    let refresh = refreshMapMarkers;

    if (this.polygon !== polygon) {
      if (this.polygon) {
        this.cleanMapPolygon();
      }
      this.polygon = polygon;
      refresh = true;
    }

    if (refresh) {
      this.updateMarkers();
    }
  }

  /**
   * Update map markers
   */
  public updateMarkers(): void {
    if (this.configService.isBrowser) {
      this.getData.next({
        type: 'markers',
        polygon: this.polygon
      });
    }
  }

  /**
   * Stay ready to proces external events (markers and popups data)
   */
  private listenEvents(): void {
    this.mapReady.pipe(
      filter((value) => value),
      switchMap(() => this.setData.pipe(
        filter((data) => !!data)
      ))
    ).subscribe((data: MapSetData) => {
      switch (data.type) {
        case 'markers':
          if (data.pois) {
            this.placePoisOnMap(data);
          } else {
            this.placeMarkersOnMap(data);
          }
          break;
        case 'popup':
          this.setMarkerPopupInfos(data);
          break;
      }
    });
  }

  /**
   * Display the given markers on the map
   * @param data The markers array
   */
  private placeMarkersOnMap({ markers, polygon, geo }: MapSetData): void {
    const source = this.getMapSource(MAP_IDS.biens.source);
    if (!source) {
      return;
    }

    this.cleanMap();

    source.setData(
      this.geojsonFeatureCollection(
        markers.map(({ id, geoloc: { lat, lng }, content, precision_geoloc }) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          properties: {
            precision_geoloc,
            content,
            lat,
            lng,
            id
          }
        }) as Feature)
      )
    );

    if (!this.searchOnMove) {
      // Draw the bounding polygons if any
      if (polygon?.length) {
        this.drawMapPolygon(polygon);
      }

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      if (geo?.length) {
        // Sometimes we don't have a bbox, use coordinates
        const bounds = new LngLatBounds(null);
        geo.forEach((bound) => {
          if (bound.bbox.length) {
            bounds.extend([bound.bbox[0], bound.bbox[1], bound.bbox[2], bound.bbox[3]]);
          } else {
            bound.coordinates.forEach((coord) => bounds.extend(coord));
          }
        });

        this.map.fitBounds(bounds, { padding: 20 });
      } else if (!this.mapCenter) {
        // No geography set so center on france
        this.map.setCenter(MAP_CENTER_FRANCE);
        this.map.setZoom(5);
      }
    }
  }

  /**
   * Display the given POIs on the map
   * @param data The POIs data set
   */
  private placePoisOnMap({ pois, precision }: MapSetData): void {

    this.cleanMap();

    pois.forEach((data, categ) => {
      this.getMapSource(categ)?.setData(
        this.geojsonFeatureCollection(
          data.map(({ content, geoloc: { lat, lng } }) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lat, lng]
            },
            properties: {
              content,
              lat,
              lng
            }
          }) as Feature)
        )
      );
    });

    if (this.mapCenter) {
      const coordinates: LngLatLike = [this.mapCenter.lng, this.mapCenter.lat];

      this.marker = new Marker().setLngLat(coordinates).addTo(this.map);

      if (precision === '1') {
        this.showMarkerCircle(this.mapCenter.lng, this.mapCenter.lat);
        this.stickyCircle = true;
      }
    }
  }

  /**
   * Draw or remove polygon on or from the map
   * @param polygon
   */
  private drawMapPolygon(polygon: string): void {
    // Convert polygon string into lat/lng array values
    const polysOnMap: Position[] = polygon.split(';').map(
      (coords) => coords.split(',').reverse().map((val) => +val)
    );

    this.getMapSource(MAP_IDS.polygons.source)?.setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [polysOnMap]
      }
    });

    const boundingRect = new LngLatBounds(null);
    polysOnMap.map((coords) => boundingRect.extend(coords as any));

    setTimeout(() => { this.map.fitBounds(boundingRect); }, 500);
    this.polygon = polygon;
  }

  /**
   * Remove all markers from the map
   */
  private cleanMap() {
    this.closeTooltip();
    this.closePopup();

    if (this.options.mode === 'annonce') {
      Object.values(MapPoi).forEach((value) => {
        this.getMapSource(value)?.setData(this.geojsonFeatureCollection());
      });
    } else {
      this.getMapSource(MAP_IDS.biens.source)?.setData(this.geojsonFeatureCollection());
    }

    if (this.marker) {
      this.marker.remove();
      this.marker = undefined;
    }
  }

  /**
   * Remove polygons from the map
   */
  private cleanMapPolygon() {
    this.getMapSource(MAP_IDS.polygons.source)?.setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: []
      }
    });
    this.polygon = '';
  }

  /**
   * Close the opened information popup if any
   */
  private closePopup(): void {
    if (this.popupDisplay) {
      this.popupDisplay.remove();
      this.popupDisplay = undefined;
      this.removeMarkerCircle();
    }
  }

  /**
   * Close the opened tooltip if any
   */
  private closeTooltip(): void {
    if (this.tooltipDisplay) {
      this.tooltipDisplay.remove();
      this.tooltipDisplay = undefined;
    }
  }

  /**
   * Signal that marker popup content is needed
   * @param e The marker info
   */
  private getMarkerPopupInfos = (e) => {
    this.getData.next({
      id: e.features[0].properties.id,
      lngLat: e.lngLat,
      type: 'popup'
    });
  };

  /**
   * Create a marker popup from the given data
   * @param param The marker data
   */
  private setMarkerPopupInfos({ popup, lngLat }: MapSetData): void {
    this.popupDisplay = new Popup().setLngLat(lngLat).setDOMContent(popup).addTo(this.map);
    this.popupDisplay.once('close', () => {
      this.closePopup();
    });
  }

  /**
   * Display tooltip when mouse is over a marker
   * @param e The marker information
   */
  private mouseEnterFunction = (event: any) => {
    const { lngLat, features } = event;
    const { geometry, properties } = features[0];

    this.map.getCanvas().style.cursor = 'pointer';

    if (geometry.type === 'Point') {
      const coordinates = geometry.coordinates.slice();
      const precision = properties.precision_geoloc;

      while (Math.abs(lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const precisionClassName = precision === '0' ? 'precision' : '';

      this.tooltipDisplay = new Popup()
        .setLngLat([coordinates[0], coordinates[1]])
        .setHTML(properties.content)
        .addTo(this.map)
        .addClassName(`map-tooltip display-text-12px font-medium ${precisionClassName}`);

      // Add circle if needed
      if ((precision === '1') && !this.stickyCircle) {
        const { lng, lat } = properties;
        this.showMarkerCircle(lng, lat);
      }
    }
  };

  /**
   * Close tooltip when mouse leave the marker area
   */
  private mouseLeaveFunction = () => {
    this.map.getCanvas().style.cursor = '';
    this.closeTooltip();

    if ((this.popupDisplay === undefined) && !this.stickyCircle) {
      this.removeMarkerCircle();
    }
  };

  /**
   * Add a circle around a marker
   * @param lng longitude
   * @param lat latitude
   */
  private showMarkerCircle(lng: number, lat: number) {
    const center = point([lng, lat]);
    const radius = 1;

    const area = circle(center, radius, { units: 'kilometers' });

    this.getMapSource(MAP_IDS.circle.source)?.setData(area);
  }

  /**
   * Remove circle
   */
  private removeMarkerCircle(): void {
    this.getMapSource(MAP_IDS.circle.source)?.setData(this.geojsonFeatureCollection());
  };

  /**
   * Add 3D buildings layer when zomm reach 5.
   * Use an 'interpolate' expression to add a smooth transition effect to the buildings as the user zooms in.
   */
  private add3DBuildingsOnZoom() {
    const layers = this.map.getStyle().layers;
    const labelLayerId = layers.find((layer) => layer.type === 'symbol' && layer.layout['text-field']).id;

    this.map.addLayer({
      id: MAP_IDS.buildings.layer,
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: 5,
      paint: {
        'fill-extrusion-opacity': 0.6,
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
        'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']]
      }
    }, labelLayerId);
  }

  /**
   * Add map layers and sources for annonces and agencies
   */
  private addSourcesAndLayers(): void {
    // Create map layers and sources
    // BIENS
    this.map.addSource(MAP_IDS.biens.source, this.geojsonCollectionSource());

    if (this.images[0]) {
      this.map.addLayer({
        id: MAP_IDS.biens.layer,
        source: MAP_IDS.biens.source,
        type: 'symbol',
        layout: {
          'icon-image': this.images[0].name,
          'icon-size': this.images[0].size
        }
      });
    } else {
      this.map.addLayer({
        id: MAP_IDS.biens.layer,
        source: MAP_IDS.biens.source,
        type: 'circle',
        paint: {
          'circle-radius': 7,
          'circle-color': '#DF1731',
          'circle-stroke-color': 'white',
          'circle-stroke-width': 2
        }
      });
    }

    // CIRCLE
    this.map.addSource(MAP_IDS.circle.source, this.geojsonCollectionSource());

    this.map.addLayer({
      id: MAP_IDS.circle.layer,
      type: 'fill',
      source: MAP_IDS.circle.source,
      paint: {
        'fill-color': 'rgba(40, 91, 184, 0.2)',
        'fill-outline-color': '#295BB8'
      }
    });

    // POLYGONS
    this.map.addSource(MAP_IDS.polygons.source, this.geojsonCollectionSource());

    // Add polygons drawing layer
    this.map.addLayer({
      id: MAP_IDS.polygons.layer,
      type: 'line',
      source: MAP_IDS.polygons.source,
      layout: {},
      paint: {
        'line-color': '#295bb8',
        'line-width': 2
      }
    });
  }

  /**
   * Remove annonces or agencies layers and sources
   */
  private removeSourcesAndLayers(): void {
    Object.values(MAP_IDS).forEach((value) => {
      if (value.layer) {
        const layer = this.map.getLayer(value.layer);
        if (layer) {
          this.map.removeLayer(value.layer);
        }
      }
      if (value.source) {
        const source = this.map.getSource(value.source);
        if (source) {
          this.map.removeSource(value.source);
        }
      }
    });
  }

  /**
   * Add map layers and sources for annonce
   */
  addPoiSourcesAndLayers(): void {
    // CIRCLE
    this.map.addSource(MAP_IDS.circle.source, this.geojsonCollectionSource());

    this.map.addLayer({
      id: MAP_IDS.circle.layer,
      type: 'fill',
      source: MAP_IDS.circle.source,
      paint: {
        'fill-color': 'rgba(40, 91, 184, 0.2)',
        'fill-outline-color': '#295BB8'
      }
    });

    this.images.forEach(({ name, size }) => {
      this.map.addSource(name, this.geojsonCollectionSource());

      this.map.addLayer({
        id: name,
        type: 'symbol',
        source: name,
        layout: {
          'visibility': 'visible',
          'icon-image': name,
          'icon-size': size
        }
      });
    });
  }

  /**
   * Remove annonce layers and sources
   */
  removePoiSourcesAndLayers(): void {
    this.images.forEach((image) => {
      const layer = this.map.getLayer(image.name);
      if (layer) {
        this.map.removeLayer(image.name);
      }

      const source = this.map.getSource(image.name);
      if (source) {
        this.map.removeSource(image.name);
      }
    });
  }

  /**
   * Add map mouse events handlers
   */
  private addEventsHandlers(): void {
    if (this.options.mode === 'annonce') {
      Object.values(MapPoi).forEach((value) => {
        this.map.on('click', value, this.mouseEnterFunction);
        this.map.on('mouseenter', value, this.mouseEnterFunction);
        this.map.on('mouseleave', value, this.mouseLeaveFunction);
      });
    } else {
      // SEARCH ON MAP MOVE
      this.map.on('moveend', this.searchOnMapMoveFunc);

      // FUNCTION TO DISPLAY AND REMOVE POPUPS
      this.map.on('click', MAP_IDS.biens.layer, this.getMarkerPopupInfos);
      this.map.on('mouseenter', MAP_IDS.biens.layer, this.mouseEnterFunction);
      this.map.on('mouseleave', MAP_IDS.biens.layer, this.mouseLeaveFunction);
    }
  }

  /**
   * Remove map events handlers
   */
  private removeEventsHandlers(): void {
    if (this.options.mode === 'annonce') {
      Object.values(MapPoi).forEach((value) => {
        this.map.off('click', value, this.mouseEnterFunction);
        this.map.off('mouseenter', value, this.mouseEnterFunction);
        this.map.off('mouseleave', value, this.mouseLeaveFunction);
      });
    } else {
      this.map.off('moveend', this.searchOnMapMoveFunc);

      this.map.off('click', MAP_IDS.biens.layer, this.getMarkerPopupInfos);
      this.map.off('mouseenter', MAP_IDS.biens.layer, this.mouseEnterFunction);
      this.map.off('mouseleave', MAP_IDS.biens.layer, this.mouseLeaveFunction);
    }
  }

  /**
   * Load all map images
   * @param images The images descriptions array
   */
  private async loadImages(images: MapImage[]): Promise<MapImage[]> {
    const result = await Promise.allSettled(
      images.map(({ name, url }) => this.loadMarkerImage(name, url))
    );

    return result.map(({ status }, i) => status ? images[i] : undefined);
  }

  /**
   * Load a map marker image
   * @param url The image url
   * @returns A promise resolving true if the image has been successfully added to the map, or false if an error occured.
   */
  private loadMarkerImage(name: string, url: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.map.loadImage(url, (error, image) => {
        if (error) {
          console.error('Error loading map icon', name, url, error);
          resolve(false);
        } else {
          this.map.addImage(name, image);
          resolve(true);
        }
      });
    });
  }

  /**
   * Get the requested GeoJSON source
   * @param id The source identifier
   * @returns The source object or undefined if not found
   */
  private getMapSource<T = GeoJSONSource>(id: string) {
    return this.map ? (this.map.getSource(id) as T) : undefined;
  }

  /**
   * Request data for the given bounding rect after a map move or zoom
   * @param event The map event
   */
  private searchOnMapMoveFunc = (event: any) => {
    if (this.searchOnMove) {
      this.getData.next({
        polygon: this.getBoundingCoords(event),
        type: 'markers'
      });
    }
  };

  /**
   * Convert the map bounding area to a polygon string
   * @param event The map event
   * @returns The polyfon formatted string
   */
  private getBoundingCoords(event: any): string {
    const bounds = event.target.getBounds();

    const ne = bounds.getNorthEast();
    const se = bounds.getSouthEast();
    const sw = bounds.getSouthWest();
    const nw = bounds.getNorthWest();

    return `${ne.lat},${ne.lng};${se.lat},${se.lng};${sw.lat},${sw.lng};${nw.lat},${nw.lng};${ne.lat},${ne.lng}`;
  };

  /**
   * Build an empty geojson source.
   * @returns The object.
   */
  private geojsonCollectionSource(): SourceSpecification {
    return {
      type: 'geojson',
      data: this.geojsonFeatureCollection()
    };
  }

  /**
   * Build a feature collection object.
   * @returns The object.
   */
  private geojsonFeatureCollection(features: any[] = []): GeoJSON.GeoJSON {
    return {
      type: 'FeatureCollection',
      features
    };
  }
}
